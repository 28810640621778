const addBodyClassForSeatmap = function(){
    const syosContainer = document.querySelector(".tn-syos");
    const $body = document.querySelector("body");

    const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            // Albert Theatre
            // 
            // Check for seat ID within each seatmap layout
            if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"9310\"]") && mutation.type === "childList") {
                // If so then add body class, and remove other body classes
                $body.classList.add('seatmap--albert-main-floor');
                $body.classList.remove('seatmap--albert-mezz','seatmap--albert-boxes');
            } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"7396\"]") && mutation.type === "childList") {
                $body.classList.add('seatmap--albert-mezz');
                $body.classList.remove('seatmap--albert-main-floor', 'seatmap--albert-boxes');
            } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"5775\"]") && mutation.type === "childList") {
                $body.classList.add('seatmap--albert-boxes');
                $body.classList.remove('seatmap--albert-main-floor', 'seatmap--albert-mezz');
            }
            // Owen Theatre
            // 
            // Check for seat ID within each seatmap layout
            if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"238160\"]") && mutation.type === "childList") {
                // If so then add body class, and remove other body classes
                $body.classList.add('seatmap--owen-main-floor');
                $body.classList.remove('seatmap--owen-mezz','seatmap--owen-balcony');
            } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"239782\"]") && mutation.type === "childList") {
                $body.classList.add('seatmap--owen-mezz');
                $body.classList.remove('seatmap--owen-main-floor', 'seatmap--owen-balcony');
            } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"241542\"]") && mutation.type === "childList") {
                $body.classList.add('seatmap--owen-balcony');
                $body.classList.remove('seatmap--owen-main-floor', 'seatmap--owen-mezz');
            }
            // Owen Theatre - Thrust
            // 
            // Check for seat ID within each seatmap layout
            if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"293775\"]") && mutation.type === "childList") {
                // If so then add body class, and remove other body classes
                $body.classList.add('seatmap--owen-thrust-main-floor');
                $body.classList.remove('seatmap--owen-thrust-mezz','seatmap--owen-thrust-balcony');
            } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"295616\"]") && mutation.type === "childList") {
                $body.classList.add('seatmap--owen-thrust-mezz');
                $body.classList.remove('seatmap--owen-thrust-main-floor', 'seatmap--owen-thrust-balcony');
            } else if (document.querySelector(".tn-syos-seat-map__seat[data-tn-seat-id=\"297376\"]") && mutation.type === "childList") {
                $body.classList.add('seatmap--owen-thrust-balcony');
                $body.classList.remove('seatmap--owen-thrust-main-floor', 'seatmap--owen-thrust-mezz');
            }
        });
    });
    observer.observe(syosContainer, {
        subtree: true,
        childList: true
    });
}

const isElementLoaded = async selector => {
    while ( document.querySelector(selector) === null) {
      await new Promise( resolve =>  requestAnimationFrame(resolve) )
    }
    return document.querySelector(selector);
  };

  isElementLoaded('.tn-syos').then((selector) => {
    addBodyClassForSeatmap();

  });